const getDateTime = (value?: any, language?: any) => {
  const date = new Date(value)

  const options: any = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'UTC',
  }

  const utcDateString = date.toLocaleString(language === 'en' ? 'en-US' : 'tr-TR', options)
  return utcDateString
}

export const getTime = (value?: any) => {
  const date = new Date(value)

  const options: any = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
    timeZone: 'UTC',
  }

  var utcTimeString = date.toLocaleString('en-US', options)
  return utcTimeString
}

export const getDate = (value?: any) => {
  const date = new Date(value)

  const options: any = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour12: true,
    timeZone: 'UTC',
  }

  const utcDateString = date.toLocaleString('en-US', options)
  return utcDateString
}

export const getYoutubeVId = (url?: any) => {
  if (url === null) {
    return ''
  }
  let results: any
  let video: any
  results = url.match('[\\?&]v=([^&#]*)')
  video = results === null ? url : results[1]

  return video
}

export const priceConversion = (value?: any, currencyCode?: string) => {
  if (currencyCode === 'TL') {
    return value.toLocaleString('tr-TR', {currency: 'TRY'})
  } else if (currencyCode === 'USD') {
    return value.toLocaleString('en-US', {currency: 'USD'})
  } else if (currencyCode === 'EUR') {
    return value.toLocaleString('en-GB', {currency: 'EUR'})
  } else {
    return value.toLocaleString('en-US', {currency: 'USD'})
  }
}

export const generatePassword = () => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+-&%#@!'
  const charactersLength = characters.length
  let counter = 0
  while (counter < 6) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export const exceptThisSymbols = ['e', 'E', '+', '-', '.']

export const languageAll = [
  'af',
  'af-NA',
  'af-ZA',
  'agq',
  'agq-CM',
  'ak',
  'ak-GH',
  'am',
  'am-ET',
  'ar',
  'ar-001',
  'ar-AE',
  'ar-BH',
  'ar-DJ',
  'ar-DZ',
  'ar-EG',
  'ar-EH',
  'ar-ER',
  'ar-IL',
  'ar-IQ',
  'ar-JO',
  'ar-KM',
  'ar-KW',
  'ar-LB',
  'ar-LY',
  'ar-MA',
  'ar-MR',
  'ar-OM',
  'ar-PS',
  'ar-QA',
  'ar-SA',
  'ar-SD',
  'ar-SO',
  'ar-SS',
  'ar-SY',
  'ar-TD',
  'ar-TN',
  'ar-YE',
  'as',
  'as-IN',
  'asa',
  'asa-TZ',
  'ast',
  'ast-ES',
  'az',
  'az-Cyrl',
  'az-Cyrl-AZ',
  'az-Latn',
  'az-Latn-AZ',
  'bas',
  'bas-CM',
  'be',
  'be-BY',
  'bem',
  'bem-ZM',
  'bez',
  'bez-TZ',
  'bg',
  'bg-BG',
  'bm',
  'bm-ML',
  'bn',
  'bn-BD',
  'bn-IN',
  'bo',
  'bo-CN',
  'bo-IN',
  'br',
  'br-FR',
  'brx',
  'brx-IN',
  'bs',
  'bs-Cyrl',
  'bs-Cyrl-BA',
  'bs-Latn',
  'bs-Latn-BA',
  'ca',
  'ca-AD',
  'ca-ES',
  'ca-FR',
  'ca-IT',
  'ccp',
  'ccp-BD',
  'ccp-IN',
  'ce',
  'ce-RU',
  'cgg',
  'cgg-UG',
  'chr',
  'chr-US',
  'ckb',
  'ckb-IQ',
  'ckb-IR',
  'cs',
  'cs-CZ',
  'cy',
  'cy-GB',
  'da',
  'da-DK',
  'da-GL',
  'dav',
  'dav-KE',
  'de',
  'de-AT',
  'de-BE',
  'de-CH',
  'de-DE',
  'de-IT',
  'de-LI',
  'de-LU',
  'dje',
  'dje-NE',
  'dsb',
  'dsb-DE',
  'dua',
  'dua-CM',
  'dyo',
  'dyo-SN',
  'dz',
  'dz-BT',
  'ebu',
  'ebu-KE',
  'ee',
  'ee-GH',
  'ee-TG',
  'el',
  'el-CY',
  'el-GR',
  'en',
  'en-001',
  'en-150',
  'en-AG',
  'en-AI',
  'en-AS',
  'en-AT',
  'en-AU',
  'en-BB',
  'en-BE',
  'en-BI',
  'en-BM',
  'en-BS',
  'en-BW',
  'en-BZ',
  'en-CA',
  'en-CC',
  'en-CH',
  'en-CK',
  'en-CM',
  'en-CX',
  'en-CY',
  'en-DE',
  'en-DG',
  'en-DK',
  'en-DM',
  'en-ER',
  'en-FI',
  'en-FJ',
  'en-FK',
  'en-FM',
  'en-GB',
  'en-GD',
  'en-GG',
  'en-GH',
  'en-GI',
  'en-GM',
  'en-GU',
  'en-GY',
  'en-HK',
  'en-IE',
  'en-IL',
  'en-IM',
  'en-IN',
  'en-IO',
  'en-JE',
  'en-JM',
  'en-KE',
  'en-KI',
  'en-KN',
  'en-KY',
  'en-LC',
  'en-LR',
  'en-LS',
  'en-MG',
  'en-MH',
  'en-MO',
  'en-MP',
  'en-MS',
  'en-MT',
  'en-MU',
  'en-MW',
  'en-MY',
  'en-NA',
  'en-NF',
  'en-NG',
  'en-NL',
  'en-NR',
  'en-NU',
  'en-NZ',
  'en-PG',
  'en-PH',
  'en-PK',
  'en-PN',
  'en-PR',
  'en-PW',
  'en-RW',
  'en-SB',
  'en-SC',
  'en-SD',
  'en-SE',
  'en-SG',
  'en-SH',
  'en-SI',
  'en-SL',
  'en-SS',
  'en-SX',
  'en-SZ',
  'en-TC',
  'en-TK',
  'en-TO',
  'en-TT',
  'en-TV',
  'en-TZ',
  'en-UG',
  'en-UM',
  'en-US',
  'en-US-POSIX',
  'en-VC',
  'en-VG',
  'en-VI',
  'en-VU',
  'en-WS',
  'en-ZA',
  'en-ZM',
  'en-ZW',
  'eo',
  'es',
  'es-419',
  'es-AR',
  'es-BO',
  'es-BR',
  'es-BZ',
  'es-CL',
  'es-CO',
  'es-CR',
  'es-CU',
  'es-DO',
  'es-EA',
  'es-EC',
  'es-ES',
  'es-GQ',
  'es-GT',
  'es-HN',
  'es-IC',
  'es-MX',
  'es-NI',
  'es-PA',
  'es-PE',
  'es-PH',
  'es-PR',
  'es-PY',
  'es-SV',
  'es-US',
  'es-UY',
  'es-VE',
  'et',
  'et-EE',
  'eu',
  'eu-ES',
  'ewo',
  'ewo-CM',
  'fa',
  'fa-AF',
  'fa-IR',
  'ff',
  'ff-CM',
  'ff-GN',
  'ff-MR',
  'ff-SN',
  'fi',
  'fi-FI',
  'fil',
  'fil-PH',
  'fo',
  'fo-DK',
  'fo-FO',
  'fr',
  'fr-BE',
  'fr-BF',
  'fr-BI',
  'fr-BJ',
  'fr-BL',
  'fr-CA',
  'fr-CD',
  'fr-CF',
  'fr-CG',
  'fr-CH',
  'fr-CI',
  'fr-CM',
  'fr-DJ',
  'fr-DZ',
  'fr-FR',
  'fr-GA',
  'fr-GF',
  'fr-GN',
  'fr-GP',
  'fr-GQ',
  'fr-HT',
  'fr-KM',
  'fr-LU',
  'fr-MA',
  'fr-MC',
  'fr-MF',
  'fr-MG',
  'fr-ML',
  'fr-MQ',
  'fr-MR',
  'fr-MU',
  'fr-NC',
  'fr-NE',
  'fr-PF',
  'fr-PM',
  'fr-RE',
  'fr-RW',
  'fr-SC',
  'fr-SN',
  'fr-SY',
  'fr-TD',
  'fr-TG',
  'fr-TN',
  'fr-VU',
  'fr-WF',
  'fr-YT',
  'fur',
  'fur-IT',
  'fy',
  'fy-NL',
  'ga',
  'ga-IE',
  'gd',
  'gd-GB',
  'gl',
  'gl-ES',
  'gsw',
  'gsw-CH',
  'gsw-FR',
  'gsw-LI',
  'gu',
  'gu-IN',
  'guz',
  'guz-KE',
  'gv',
  'gv-IM',
  'ha',
  'ha-GH',
  'ha-NE',
  'ha-NG',
  'haw',
  'haw-US',
  'he',
  'he-IL',
  'hi',
  'hi-IN',
  'hr',
  'hr-BA',
  'hr-HR',
  'hsb',
  'hsb-DE',
  'hu',
  'hu-HU',
  'hy',
  'hy-AM',
  'id',
  'id-ID',
  'ig',
  'ig-NG',
  'ii',
  'ii-CN',
  'is',
  'is-IS',
  'it',
  'it-CH',
  'it-IT',
  'it-SM',
  'it-VA',
  'ja',
  'ja-JP',
  'jgo',
  'jgo-CM',
  'jmc',
  'jmc-TZ',
  'ka',
  'ka-GE',
  'kab',
  'kab-DZ',
  'kam',
  'kam-KE',
  'kde',
  'kde-TZ',
  'kea',
  'kea-CV',
  'khq',
  'khq-ML',
  'ki',
  'ki-KE',
  'kk',
  'kk-KZ',
  'kkj',
  'kkj-CM',
  'kl',
  'kl-GL',
  'kln',
  'kln-KE',
  'km',
  'km-KH',
  'kn',
  'kn-IN',
  'ko',
  'ko-KP',
  'ko-KR',
  'kok',
  'kok-IN',
  'ks',
  'ks-IN',
  'ksb',
  'ksb-TZ',
  'ksf',
  'ksf-CM',
  'ksh',
  'ksh-DE',
  'kw',
  'kw-GB',
  'ky',
  'ky-KG',
  'lag',
  'lag-TZ',
  'lb',
  'lb-LU',
  'lg',
  'lg-UG',
  'lkt',
  'lkt-US',
  'ln',
  'ln-AO',
  'ln-CD',
  'ln-CF',
  'ln-CG',
  'lo',
  'lo-LA',
  'lrc',
  'lrc-IQ',
  'lrc-IR',
  'lt',
  'lt-LT',
  'lu',
  'lu-CD',
  'luo',
  'luo-KE',
  'luy',
  'luy-KE',
  'lv',
  'lv-LV',
  'mas',
  'mas-KE',
  'mas-TZ',
  'mer',
  'mer-KE',
  'mfe',
  'mfe-MU',
  'mg',
  'mg-MG',
  'mgh',
  'mgh-MZ',
  'mgo',
  'mgo-CM',
  'mk',
  'mk-MK',
  'ml',
  'ml-IN',
  'mn',
  'mn-MN',
  'mr',
  'mr-IN',
  'ms',
  'ms-BN',
  'ms-MY',
  'ms-SG',
  'mt',
  'mt-MT',
  'mua',
  'mua-CM',
  'my',
  'my-MM',
  'mzn',
  'mzn-IR',
  'naq',
  'naq-NA',
  'nb',
  'nb-NO',
  'nb-SJ',
  'nd',
  'nd-ZW',
  'nds',
  'nds-DE',
  'nds-NL',
  'ne',
  'ne-IN',
  'ne-NP',
  'nl',
  'nl-AW',
  'nl-BE',
  'nl-BQ',
  'nl-CW',
  'nl-NL',
  'nl-SR',
  'nl-SX',
  'nmg',
  'nmg-CM',
  'nn',
  'nn-NO',
  'nnh',
  'nnh-CM',
  'nus',
  'nus-SS',
  'nyn',
  'nyn-UG',
  'om',
  'om-ET',
  'om-KE',
  'or',
  'or-IN',
  'os',
  'os-GE',
  'os-RU',
  'pa',
  'pa-Arab',
  'pa-Arab-PK',
  'pa-Guru',
  'pa-Guru-IN',
  'pl',
  'pl-PL',
  'ps',
  'ps-AF',
  'pt',
  'pt-AO',
  'pt-BR',
  'pt-CH',
  'pt-CV',
  'pt-GQ',
  'pt-GW',
  'pt-LU',
  'pt-MO',
  'pt-MZ',
  'pt-PT',
  'pt-ST',
  'pt-TL',
  'qu',
  'qu-BO',
  'qu-EC',
  'qu-PE',
  'rm',
  'rm-CH',
  'rn',
  'rn-BI',
  'ro',
  'ro-MD',
  'ro-RO',
  'rof',
  'rof-TZ',
  'ru',
  'ru-BY',
  'ru-KG',
  'ru-KZ',
  'ru-MD',
  'ru-RU',
  'ru-UA',
  'rw',
  'rw-RW',
  'rwk',
  'rwk-TZ',
  'sah',
  'sah-RU',
  'saq',
  'saq-KE',
  'sbp',
  'sbp-TZ',
  'se',
  'se-FI',
  'se-NO',
  'se-SE',
  'seh',
  'seh-MZ',
  'ses',
  'ses-ML',
  'sg',
  'sg-CF',
  'shi',
  'shi-Latn',
  'shi-Latn-MA',
  'shi-Tfng',
  'shi-Tfng-MA',
  'si',
  'si-LK',
  'sk',
  'sk-SK',
  'sl',
  'sl-SI',
  'smn',
  'smn-FI',
  'sn',
  'sn-ZW',
  'so',
  'so-DJ',
  'so-ET',
  'so-KE',
  'so-SO',
  'sq',
  'sq-AL',
  'sq-MK',
  'sq-XK',
  'sr',
  'sr-Cyrl',
  'sr-Cyrl-BA',
  'sr-Cyrl-ME',
  'sr-Cyrl-RS',
  'sr-Cyrl-XK',
  'sr-Latn',
  'sr-Latn-BA',
  'sr-Latn-ME',
  'sr-Latn-RS',
  'sr-Latn-XK',
  'sv',
  'sv-AX',
  'sv-FI',
  'sv-SE',
  'sw',
  'sw-CD',
  'sw-KE',
  'sw-TZ',
  'sw-UG',
  'ta',
  'ta-IN',
  'ta-LK',
  'ta-MY',
  'ta-SG',
  'te',
  'te-IN',
  'teo',
  'teo-KE',
  'teo-UG',
  'tg',
  'tg-TJ',
  'th',
  'th-TH',
  'ti',
  'ti-ER',
  'ti-ET',
  'to',
  'to-TO',
  'tr',
  'tr-CY',
  'tr-TR',
  'tt',
  'tt-RU',
  'twq',
  'twq-NE',
  'tzm',
  'tzm-MA',
  'ug',
  'ug-CN',
  'uk',
  'uk-UA',
  'ur',
  'ur-IN',
  'ur-PK',
  'uz',
  'uz-Arab',
  'uz-Arab-AF',
  'uz-Cyrl',
  'uz-Cyrl-UZ',
  'uz-Latn',
  'uz-Latn-UZ',
  'vai',
  'vai-Latn',
  'vai-Latn-LR',
  'vai-Vaii',
  'vai-Vaii-LR',
  'vi',
  'vi-VN',
  'vun',
  'vun-TZ',
  'wae',
  'wae-CH',
  'wo',
  'wo-SN',
  'xog',
  'xog-UG',
  'yav',
  'yav-CM',
  'yi',
  'yi-001',
  'yo',
  'yo-BJ',
  'yo-NG',
  'yue',
  'yue-Hans',
  'yue-Hans-CN',
  'yue-Hant',
  'yue-Hant-HK',
  'zgh',
  'zgh-MA',
  'zh',
  'zh-Hans',
  'zh-Hans-CN',
  'zh-Hans-HK',
  'zh-Hans-MO',
  'zh-Hans-SG',
  'zh-Hant',
  'zh-Hant-HK',
  'zh-Hant-MO',
  'zh-Hant-TW',
  'zu',
  'zu-ZA',
]

export const getFreightIcon: any = {
  1: '/media/new-icon/07.png',
  2: '/media/new-icon/07.png',
  3: '/media/new-icon/01.png',
  4: '/media/new-icon/04.png',
  5: '/media/new-icon/13.png',
  6: '/media/new-icon/13.png',
}

export const getMapVehicleIcon: any = {
  1: '/media/new-icon/01.png',
  '1a': '/media/new-icon/02.png',
  '1b': '/media/new-icon/03.png',
  2: '/media/new-icon/01.png',
  '2a': '/media/new-icon/02.png',
  '2b': '/media/new-icon/03.png',
  3: '/media/new-icon/07.png',
  '3a': '/media/new-icon/08.png',
  '3b': '/media/new-icon/09.png',
  4: '/media/new-icon/04.png',
  '4a': '/media/new-icon/05.png',
  '4b': '/media/new-icon/06.png',
  5: '/media/new-icon/13.png',
  '5a': '/media/new-icon/14.png',
  '5b': '/media/new-icon/15.png',
  6: '/media/new-icon/13.png',
  '6a': '/media/new-icon/14.png',
  '6b': '/media/new-icon/15.png',
}

export const getCountries: any = {
  TR: '/media/flags/turkey.svg',
  AD: '/media/flags/andorra.svg',
  AE: '/media/flags/united-arab-emirates.svg',
  AF: '/media/flags/afghanistan.svg',
  AG: '/media/flags/antigua-and-barbuda.svg',
  AI: '/media/flags/anguilla.svg',
  AL: '/media/flags/albania.svg',
  AM: '/media/flags/armenia.svg',
  AO: '/media/flags/angola.svg',
  AQ: '/media/flags/angola.svg',
  AR: '/media/flags/argentina.svg',
  AS: '/media/flags/american-samoa.svg',
  AT: '/media/flags/austria.svg',
  AU: '/media/flags/australia.svg',
  AW: '/media/flags/aruba.svg',
  AZ: '/media/flags/azerbaijan.svg',
  BA: '/media/flags/bosnia-and-herzegovina.svg',
  BB: '/media/flags/barbados.svg',
  BD: '/media/flags/bangladesh.svg',
  BE: '/media/flags/belgium.svg',
  BF: '/media/flags/burkina-faso.svg',
  BG: '/media/flags/bulgaria.svg',
  BH: '/media/flags/Bahrain.svg',
  BI: '/media/flags/burundi.svg',
  BJ: '/media/flags/benin.svg',
  BL: '/media/flags/benin.svg',
  BM: '/media/flags/bermuda.svg',
  BN: '/media/flags/brunei.svg',
  BO: '/media/flags/bolivia.svg',
  BR: '/media/flags/brazil.svg',
  BS: '/media/flags/bahamas.svg',
  BT: '/media/flags/bhutan.svg',
  BW: '/media/flags/botswana.svg',
  BY: '/media/flags/belarus.svg',
  BZ: '/media/flags/belize.svg',
  CA: '/media/flags/canada.svg',
  CC: '/media/flags/cocos-island.svg',
  CD: '/media/flags/cocos-island.svg',
  CF: '/media/flags/central-african-republic.svg',
  CG: '/media/flags/central-african-republic.svg',
  CH: '/media/flags/switzerland.svg',
  CI: '/media/flags/swaziland.svg',
  CK: '/media/flags/cook-islands.svg',
  CL: '/media/flags/chile.svg',
  CM: '/media/flags/cameroon.svg',
  CN: '/media/flags/china.svg',
  CO: '/media/flags/colombia.svg',
  CR: '/media/flags/costa-rica.svg',
  CU: '/media/flags/cuba.svg',
  CV: '/media/flags/cape-verde.svg',
  CW: '/media/flags/curacao.svg',
  CX: '/media/flags/christmas-island.svg',
  CY: '/media/flags/cyprus.svg',
  CZ: '/media/flags/czech-republic.svg',
  DE: '/media/flags/germany.svg',
  DJ: '/media/flags/djibouti.svg',
  DK: '/media/flags/denmark.svg',
  DM: '/media/flags/dominica.svg',
  DO: '/media/flags/dominican-republic.svg',
  DZ: '/media/flags/algeria.svg',
  EC: '/media/flags/ecuador.svg',
  EE: '/media/flags/estonia.svg',
  EG: '/media/flags/egypt.svg',
  EH: '/media/flags/sahrawi-arab-democratic-republic.svg',
  ER: '/media/flags/eritrea.svg',
  ES: '/media/flags/spain.svg',
  ET: '/media/flags/ethiopia.svg',
  FI: '/media/flags/finland.svg',
  FJ: '/media/flags/fiji.svg',
  FK: '/media/flags/falkland-islands.svg',
  FM: '/media/flags/micronesia.svg',
  FO: '/media/flags/micronesia.svg',
  FR: '/media/flags/france.svg',
  GA: '/media/flags/gabon.svg',
  GD: '/media/flags/grenada.svg',
  GE: '/media/flags/georgia.svg',
  GF: '/media/flags/georgia.svg',
  GG: '/media/flags/guernsey.svg',
  GH: '/media/flags/ghana.svg',
  GI: '/media/flags/gibraltar.svg',
  GL: '/media/flags/greenland.svg',
  GM: '/media/flags/gambia.svg',
  GN: '/media/flags/guinea.svg',
  GP: '/media/flags/guinea.svg',
  GQ: '/media/flags/equatorial-guinea.svg',
  GR: '/media/flags/greece.svg',
  GT: '/media/flags/guatemala.svg',
  GU: '/media/flags/guam.svg',
  GW: '/media/flags/guinea-bissau.svg',
  GY: '/media/flags/guinea-bissau.svg',
  HK: '/media/flags/hong-kong.svg',
  HN: '/media/flags/honduras.svg',
  HR: '/media/flags/croatia.svg',
  HT: '/media/flags/haiti.svg',
  HU: '/media/flags/hungary.svg',
  ID: '/media/flags/indonesia.svg',
  IE: '/media/flags/ireland.svg',
  IL: '/media/flags/israel.svg',
  IM: '/media/flags/isle-of-man.svg',
  IN: '/media/flags/india.svg',
  IO: '/media/flags/british-indian-ocean-territory.svg',
  IQ: '/media/flags/iraq.svg',
  IR: '/media/flags/iran.svg',
  IS: '/media/flags/iceland.svg',
  IT: '/media/flags/italy.svg',
  JE: '/media/flags/jersey.svg',
  JM: '/media/flags/jamaica.svg',
  JO: '/media/flags/jordan.svg',
  JP: '/media/flags/japan.svg',
  KE: '/media/flags/kenya.svg',
  KG: '/media/flags/kyrgyzstan.svg',
  KH: '/media/flags/cambodia.svg',
  KI: '/media/flags/kiribati.svg',
  KM: '/media/flags/comoros.svg',
  KN: '/media/flags/saint-kitts-and-nevis.svg',
  KP: '/media/flags/north-korea.svg',
  KR: '/media/flags/south-korea.svg',
  KW: '/media/flags/kuwait.svg',
  KY: '/media/flags/cayman-islands.svg',
  KZ: '/media/flags/kazakhstan.svg',
  LB: '/media/flags/lebanon.svg',
  LC: '/media/flags/lebanon.svg',
  LI: '/media/flags/liechtenstein.svg',
  LK: '/media/flags/sri-lanka.svg',
  LR: '/media/flags/liberia.svg',
  LS: '/media/flags/lesotho.svg',
  LT: '/media/flags/lithuania.svg',
  LU: '/media/flags/luxembourg.svg',
  LV: '/media/flags/latvia.svg',
  LY: '/media/flags/libya.svg',
  MA: '/media/flags/morocco.svg',
  MC: '/media/flags/monaco.svg',
  MD: '/media/flags/moldova.svg',
  ME: '/media/flags/montenegro.svg',
  MF: '/media/flags/montenegro.svg',
  MG: '/media/flags/madagascar.svg',
  MH: '/media/flags/marshall-island.svg',
  MK: '/media/flags/republic-of-macedonia.svg',
  ML: '/media/flags/mali.svg',
  MM: '/media/flags/myanmar.svg',
  MN: '/media/flags/mongolia.svg',
  MO: '/media/flags/macao.svg',
  MP: '/media/flags/northern-mariana-islands.svg',
  MQ: '/media/flags/martinique.svg',
  MR: '/media/flags/mauritania.svg',
  MS: '/media/flags/montserrat.svg',
  MT: '/media/flags/malta.svg',
  MU: '/media/flags/mauritius.svg',
  MV: '/media/flags/maldives.svg',
  MW: '/media/flags/malawi.svg',
  MX: '/media/flags/mexico.svg',
  MY: '/media/flags/malaysia.svg',
  MZ: '/media/flags/mozambique.svg',
  NA: '/media/flags/namibia.svg',
  NC: '/media/flags/france.svg',
  NE: '/media/flags/niger.svg',
  NF: '/media/flags/norfolk-island.svg',
  NG: '/media/flags/nigeria.svg',
  NI: '/media/flags/nicaragua.svg',
  NL: '/media/flags/netherlands.svg',
  NO: '/media/flags/norway.svg',
  NP: '/media/flags/nepal.svg',
  NR: '/media/flags/nauru.svg',
  NU: '/media/flags/niue.svg',
  NZ: '/media/flags/new-zealand.svg',
  OM: '/media/flags/oman.svg',
  PA: '/media/flags/panama.svg',
  PE: '/media/flags/peru.svg',
  PF: '/media/flags/french-polynesia.svg',
  PG: '/media/flags/papua-new-guinea.svg',
  PH: '/media/flags/philippines.svg',
  PK: '/media/flags/pakistan.svg',
  PL: '/media/flags/poland.svg',
  PM: '/media/flags/france.svg',
  PN: '/media/flags/pitcairn-islands.svg',
  PR: '/media/flags/puerto-rico.svg',
  PS: '/media/flags/palestine.svg',
  PT: '/media/flags/portugal.svg',
  PW: '/media/flags/palau.svg',
  PY: '/media/flags/paraguay.svg',
  QA: '/media/flags/qatar.svg',
  RE: '/media/flags/france.svg',
  RO: '/media/flags/romania.svg',
  RS: '/media/flags/serbia.svg',
  RU: '/media/flags/russia.svg',
  RW: '/media/flags/rwanda.svg',
  SA: '/media/flags/saudi-arabia.svg',
  SB: '/media/flags/solomon-islands.svg',
  SC: '/media/flags/seychelles.svg',
  SD: '/media/flags/sudan.svg',
  SE: '/media/flags/sweden.svg',
  SG: '/media/flags/singapore.svg',
  SI: '/media/flags/slovenia.svg',
  SJ: '/media/flags/slovenia.svg',
  SK: '/media/flags/slovakia.svg',
  SL: '/media/flags/sierra-leone.svg',
  SM: '/media/flags/san-marino.svg',
  SN: '/media/flags/senegal.svg',
  SO: '/media/flags/somalia.svg',
  SR: '/media/flags/suriname.svg',
  SS: '/media/flags/south-sudan.svg',
  ST: '/media/flags/sao-tome-and-prince.svg',
  SV: '/media/flags/el-salvador.svg',
  SX: '/media/flags/sint-maarten.svg',
  SY: '/media/flags/syria.svg',
  SZ: '/media/flags/swaziland.svg',
  TC: '/media/flags/turks-and-caicos.svg',
  TD: '/media/flags/chad.svg',
  TF: '/media/flags/chad.svg',
  TG: '/media/flags/togo.svg',
  TH: '/media/flags/thailand.svg',
  TJ: '/media/flags/tajikistan.svg',
  TK: '/media/flags/tokelau.svg',
  TL: '/media/flags/east-timor.svg',
  TM: '/media/flags/turkmenistan.svg',
  TN: '/media/flags/tunisia.svg',
  TO: '/media/flags/tonga.svg',
  TT: '/media/flags/trinidad-and-tobago.svg',
  TV: '/media/flags/tuvalu.svg',
  TW: '/media/flags/taiwan.svg',
  TZ: '/media/flags/tanzania.svg',
  UA: '/media/flags/ukraine.svg',
  GB: '/media/flags/uk.svg',
  UG: '/media/flags/uganda.svg',
  US: '/media/flags/united-states.svg',
  UY: '/media/flags/uruguay.svg',
  UZ: '/media/flags/uzbekistan.svg',
  VA: '/media/flags/uzbekistan.svg',
  VC: '/media/flags/st-vincent-and-the-grenadines.svg',
  VE: '/media/flags/venezuela.svg',
  VG: '/media/flags/virgin-islands.svg',
  VI: '/media/flags/virgin-islands.svg',
  VN: '/media/flags/vietnam.svg',
  VU: '/media/flags/vanuatu.svg',
  WF: '/media/flags/france.svg',
  WS: '/media/flags/samoa.svg',
  XK: '/media/flags/kosovo.svg',
  XW: '/media/flags/kosovo.svg',
  YE: '/media/flags/yemen.svg',
  YT: '/media/flags/france.svg',
  ZA: '/media/flags/south-africa.svg',
  ZM: '/media/flags/zambia.svg',
  ZW: '/media/flags/zimbabwe.svg',
}

export const BlogsData: any = (intl: any) => {
  return [
    {
      title: intl.formatMessage({id: 'blog.speedEfficAir.title'}),
      description: intl.formatMessage({id: 'Seo.airFreightShipping.description'}),
      url: '/blog/speed-and-efficiency-advantages-of-air',
      imgUrl: '/media/air_freight_shipping.png',
    },
    {
      title: intl.formatMessage({id: 'blog.freightExchImprove.metaTitle'}),
      description: intl.formatMessage({id: 'Seo.howFreightExcImprvRoadTran.description'}),
      url: '/blog/how-freight-exhcnages-improve-road-transportation-services',
      imgUrl: '/media/improveRoad.png',
    },
    {
      title: intl.formatMessage({id: 'blog.freightExchImprove.metaTitle'}),
      description: intl.formatMessage({id: 'Seo.evoultionFreightExchanges.description'}),
      url: '/blog/the-evolution-of-freight-exchanges-from-traditional-to-online-plateforms',
      imgUrl: '/media/evolution-freight.png',
    },
    {
      title: intl.formatMessage({id: 'blog.freightExchTruck.title'}),
      description: intl.formatMessage({id: 'Seo.freightExchngTrucking.description'}),
      url: '/blog/freight-exchange-trucking-streamlining-road-transportation',
      imgUrl: '/media/truck-exchange-trucking.png',
    },
    {
      title: intl.formatMessage({id: 'blog.airFreightShippingKeyfactor.title'}),
      description: intl.formatMessage({id: 'blog.airFreightShippingKeyfactor.seo.description'}),
      url: '/blog/air-freight-shipping-key-factors-driving-growth-and-efficiency',
      imgUrl: '/media/air_freight.png',
    },
    {
      title: intl.formatMessage({id: 'blog.maximizingEfficiency.title'}),
      description: intl.formatMessage({id: 'blog.maximizingEfficiency.seo.description'}),
      url: '/blog/maximizing-efficiency-the-benefits-of-full-truckload-freight',
      imgUrl: '/media/maximize_efficiency.png',
    },
    {
      title: intl.formatMessage({id: 'blog.oceanFreightShipping.title'}),
      description: intl.formatMessage({id: 'Seo.oceanFreightShipping.description'}),
      url: '/blog/ocean-freight-shipping-navigating-the-waters-of-global-trade',
      imgUrl: '/media/ocean_freight_shipping.png',
    },
    {
      title: intl.formatMessage({id: 'blog.futureOfInter.title'}),
      description: intl.formatMessage({id: 'Seo.futureOfInternational.description'}),
      url: '/blog/the-future-of-international-freight-exchanges-in-global-trade',
      imgUrl: '/media/future-of-international-flight.png',
    },
    {
      title: intl.formatMessage({id: 'blog.unlockinfEfficency.title'}),
      description: intl.formatMessage({id: 'Seo.unlockingEfficiency.description'}),
      url: '/blog/unlocking-efficiency-the-evolution-and-benefits-of-freight-exchanges',
      imgUrl: '/media/unlocking_efficiency.png',
    },
    {
      title: intl.formatMessage({id: 'blog.whyFreightExchangePlat.title'}),
      description: intl.formatMessage({id: 'Seo.whyFlightExchngPlateform.description'}),
      url: '/blog/why-freight-exchange-platform-is-essential-for-modern-logistics',
      imgUrl: '/media/what-freight-exchange-platform.png',
    },
    {
      title: intl.formatMessage({id: 'blog.maritimeFreightForward.title'}),
      description: intl.formatMessage({id: 'blog.maritimeFreightForward.seo.description'}),
      url: '/blog/top-strategies-for-effective-maritime-freight-forwarding-in-the-modern-era',
      imgUrl: '/media/maritime_freight_forwarder.png',
    },
    {
      title: intl.formatMessage({id: 'blog.undertandInterModal.title'}),
      description: intl.formatMessage({id: 'blog.undertandInterModal.seo.description'}),
      url: '/blog/understanding-intermodal-freight-transport-advantages-and-key-considerations',
      imgUrl: '/media/intermodal_freight.png',
    },
    {
      title: intl.formatMessage({id: 'blog.howOceanFreightBoost.title1'}),
      description: intl.formatMessage({id: 'blog.howOceanFreightBoost.seo.description'}),
      url: '/blog/how-ocean-freight-shipping-can-boost-your-business-growth',
      imgUrl: '/media/aerial-view-cargo-ship-cargo-container-harbor.jpg',
    },
    {
      title: intl.formatMessage({id: 'blog.howToChooseRightTranspo.title1'}),
      description: intl.formatMessage({id: 'blog.howToChooseRightTranspo.seo.description'}),
      url: '/blog/how-to-choose-the-right-road-transportation-service-for-your-needs',
      imgUrl: '/media/how-to-choose-road-transport.jpg',
    },
    {
      title: intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.title1'}),
      description: intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.seo.description'}),
      url: '/blog/the-future-of-buying-freight-trucks-why-online-sales-are-the-way-to-go',
      imgUrl: '/media/future-of-buying-freight-truck.jpg',
    },
    {
      title: intl.formatMessage({id: 'blog.theUltimateGuideToAirShip.title1'}),
      description: intl.formatMessage({id: 'blog.theUltimateGuideToAirShip.seo.description'}),
      url: '/blog/the-ultimate-guide-to-air-freight-shipping-services',
      imgUrl: '/media/ultimateGuide-air-shipping.png',
    },
    {
      title: intl.formatMessage({id: 'blog.understandingTheBasicTruckLoad.title1'}),
      description: intl.formatMessage({id: 'blog.understandingTheBasicTruckLoad.seo.description'}),
      url: '/blog/understanding-the-basics-of-full-truckload-transportation',
      imgUrl: '/media/full-truck-load-freight.jpg',
    },
    {
      title: intl.formatMessage({id: 'blog.futureOfRoadTransportation.title'}),
      description: intl.formatMessage({id: 'blog.futureOfRoadTransportation.seo.descripton'}),
      url: '/blog/the-future-of-road-transportation-service-what-you-need-to-know',
      imgUrl: '/media/future-of-road-transportation.png',
    },
  ]
}

export default getDateTime
