/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout} from '../pages/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import {Login} from '../pages/auth/components/Login'
import {AuthRegistration} from '../pages/auth/components/AuthRegistration'
import {CreatePublicFreight} from '../pages/FreightOperator/CreatePublicFreight'
import {PublicContainerDetails} from '../pages/Carrier/PublicContainerDetails/PublicContainerDetails'
import {ForwardPage} from '../pages/Carrier/ForwardPage/ForwardPage'
import {ForgotPassword} from '../pages/auth/components/ForgotPassword'
// import Landing from '../pages/DefaultPages/LandingPage/Landing'
import {NewHomePage} from '../pages/DefaultPages/NewHomePage/NewHomePage'
import AboutUs from '../pages/DefaultPages/AboutUs/AboutUs'
import PrivacyData from '../pages/DefaultPages/Privacy/Privacy'
import Agreements from '../pages/DefaultPages/PrivacyPolicy/agreements'
import SetPassword from '../pages/DefaultPages/SetPassword/SetPassword'
import RenewPassword from '../pages/DefaultPages/RenewPassword/RenewPassword'
import {PublicVehicleBuyingSelling} from '../pages/FreightOperator/PublicVehicleBuyingSelling/PublicVehicleBuyingSelling'
import PublicAvailableVehicles from '../pages/FreightOperator/PublicAvailableVehicles/PublicAvailableVehicles'
import {PublicVehicleBuySellDetails} from '../pages/FreightOperator/PublicVehicleBuySellDetails/PublicVehicleBuySellDetails'
import {PublicFreightPostings} from '../pages/Carrier/PublicFreightPostings/PublicFreightPostings'
import FreightExchangeImproveServices from '../../blogs/Blog/FreightExchangeImproveServices'
import EvolutionFreightExchanges from '../../blogs/Blog/EvolutionFreightExchanges'
import SpeedEfficiencyair from '../../blogs/Blog/SpeedEfficiencyair'
import FreightExchangeTruckingStreaming from '../../blogs/Blog/FreightExchangeTruckingStreaming'
import OceanFreightShipping from '../../blogs/Blog/OceanFreightShipping'
import FutureOfInternationalFlightExchanges from '../../blogs/Blog/FutureOfInternationalFlightExchanges'
import UnlockingEfficiency from '../../blogs/Blog/UnlockingEfficiency'
import WhyFlightExchangePlatform from '../../blogs/Blog/WhyFlightExchangePlatform'
import AirFreightShippingKeyfactorDriving from '../../blogs/Blog/AirFreightShippingKeyfactorDriving'
import MaximizingEfficiency from '../../blogs/Blog/MaximizingEfficiency'
import TheFutureOfRoadTransportationService from '../../blogs/Blog/TheFutureOfRoadTransportationService'
import MaritimeFreightForwarding from '../../blogs/Blog/MaritimeFreightForwarding'
import UnderstandInterModalFreight from '../../blogs/Blog/UnderstandInterModalFreight'
import HowOceanFreightBoostYourBusiness from '../../blogs/Blog/HowOceanFreightBoostYourBusiness'
import HowToChooseRightTransportationRoadServices from '../../blogs/Blog/HowToChooseRightTransportationRoadServices'
import TheFutureOfBuyingFreightTrucks from '../../blogs/Blog/TheFutureOfBuyingFreightTrucks'
import TheUltimateGuideToAirShipping from '../../blogs/Blog/TheUltimateGuideToAirShipping'
import UnderstandingTheBasicOfFullTruckLoad from '../../blogs/Blog/UnderstandingTheBasicOfFullTruckLoad'
import PublicFinancialAdvisor from '../pages/DefaultPages/PublicFinancialAdvisor/PublicFinancialAdvisor'
import BlogsComponent from '../../blogs/blogs'


const Routes: FC = () => {
  const dashRoute = (roles: any, from: string = '/auth') => {
    if (roles.includes('ORGANIZATION_OWNER')) return <Redirect from={from} to='/admin/dashboard' />
    else if (roles.includes('SYSTEM_OPERATOR'))
      return <Redirect from={from} to='/admin/dashboard' />
    else if (roles.includes('CARRIER_OPERATOR'))
      return <Redirect from={from} to='/carrier/dashboard' />
    else if (roles.includes('FREIGHT_OPERATOR'))
      return <Redirect from={from} to='/fr-op/dashboard' />
    else if (roles.includes('FORWARDER_OPERATOR'))
      return <Redirect from={from} to='/forwarder/dashboard' />
    else return <Redirect from={from} to='/' />
  }
  const forceToChangePassword = useSelector<RootState>(
    ({auth}) => auth.forceToChangePassword,
    shallowEqual
  )
  const role = useSelector<RootState>(({auth}) => auth.role, shallowEqual)
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  return (
    <>
      <Switch>
        {/* <Redirect exact from='/' to='/home' /> */}
        <Route exact path='/' component={NewHomePage} />
        <Route path='/about-us' component={AboutUs} />
        {/*route sayfalar*/}
        <Route path='/agreements' component={Agreements} />
        <Route path='/privacy-policy' component={PrivacyData} />
        {/* <Route path='/privacy' component={PrivacyData} /> */}

        {/*html sayfalar*/}

        <Route path='/blogs' component={BlogsComponent} />

        <Route path='/blog/speed-and-efficiency-advantages-of-air' component={SpeedEfficiencyair} />

        <Route
          path='/blog/how-freight-exhcnages-improve-road-transportation-services'
          component={FreightExchangeImproveServices}
        />

        <Route
          path='/blog/the-evolution-of-freight-exchanges-from-traditional-to-online-plateforms'
          component={EvolutionFreightExchanges}
        />

        <Route
          path='/blog/freight-exchange-trucking-streamlining-road-transportation'
          component={FreightExchangeTruckingStreaming}
        />

        <Route
          path='/blog/ocean-freight-shipping-navigating-the-waters-of-global-trade'
          component={OceanFreightShipping}
        />

        <Route
          path='/blog/the-future-of-international-freight-exchanges-in-global-trade'
          component={FutureOfInternationalFlightExchanges}
        />

        <Route
          path='/blog/unlocking-efficiency-the-evolution-and-benefits-of-freight-exchanges'
          component={UnlockingEfficiency}
        />

        <Route
          path='/blog/why-freight-exchange-platform-is-essential-for-modern-logistics'
          component={WhyFlightExchangePlatform}
        />

        <Route
          path='/blog/air-freight-shipping-key-factors-driving-growth-and-efficiency'
          component={AirFreightShippingKeyfactorDriving}
        />

        <Route
          path='/blog/maximizing-efficiency-the-benefits-of-full-truckload-freight'
          component={MaximizingEfficiency}
        />

        <Route
          path='/blog/the-future-of-road-transportation-service-what-you-need-to-know'
          component={TheFutureOfRoadTransportationService}
        />

        <Route
          path='/blog/top-strategies-for-effective-maritime-freight-forwarding-in-the-modern-era'
          component={MaritimeFreightForwarding}
        />

        <Route
          path='/blog/understanding-intermodal-freight-transport-advantages-and-key-considerations'
          component={UnderstandInterModalFreight}
        />

        <Route
          path='/blog/how-ocean-freight-shipping-can-boost-your-business-growth'
          component={HowOceanFreightBoostYourBusiness}
        />

        <Route
          path='/blog/how-to-choose-the-right-road-transportation-service-for-your-needs'
          component={HowToChooseRightTransportationRoadServices}
        />

        <Route
          path='/blog/the-future-of-buying-freight-trucks-why-online-sales-are-the-way-to-go'
          component={TheFutureOfBuyingFreightTrucks}
        />

        <Route
          path='/blog/the-ultimate-guide-to-air-freight-shipping-services'
          component={TheUltimateGuideToAirShipping}
        />

        <Route
          path='/blog/understanding-the-basics-of-full-truckload-transportation'
          component={UnderstandingTheBasicOfFullTruckLoad}
        />

        <Route
          path='/privacy_policy/'
          render={() => {
            window.location.href = 'agreement/privacy_policy.html'
          }}
        />
        <Route
          path='/privacy_policy_tr/'
          render={() => {
            window.location.href = '/agreement/privacy_policy_tr.html'
          }}
        />
        <Route
          path='/privacy_policy_en/'
          render={() => {
            window.location.href = '/agreement/privacy_policy_en.html'
          }}
        />
        <Route
          path='/privacy_policy_ru/'
          render={() => {
            window.location.href = '/agreement/privacy_policy_ru.html'
          }}
        />

        <Route
          path='/delivery_refund_tr/'
          render={() => {
            window.location.href = '/agreement/delivery_and_refund_tr.html'
          }}
        />
        <Route
          path='/delivery_refund_en/'
          render={() => {
            window.location.href = '/agreement/delivery_and_refund_en.html'
          }}
        />
        <Route
          path='/delivery_refund_ru/'
          render={() => {
            window.location.href = '/agreement/delivery_and_refund_ru.html'
          }}
        />

        <Route
          path='/distance_sales_tr/'
          render={() => {
            window.location.href = '/agreement/distance_sales_agreement_tr.html'
          }}
        />
        <Route
          path='/distance_sales_en/'
          render={() => {
            window.location.href = '/agreement/distance_sales_agreement_en.html'
          }}
        />
        <Route
          path='/distance_sales_ru/'
          render={() => {
            window.location.href = '/agreement/distance_sales_agreement_ru.html'
          }}
        />

        <Route
          exact
          path='/agreement/'
          render={() => {
            window.location.href = 'agreement/agreement.html'
          }}
        />
        {/*asset links*/}
        <Route
          path='/apple-app-site-association'
          render={() => {
            window.location.href = '/apple-app-site-association'
          }}
        />
        <Route
          exact
          path='/assetlinks.json'
          render={() => {
            window.location.href = '/assetlinks.json'
          }}
        />
        <Route
          path='/.well-known/apple-app-site-association'
          render={() => {
            window.location.href = '/.well-known/apple-app-site-association'
          }}
        />
        <Route
          exact
          path='/.well-known/assetlinks.json'
          render={() => {
            window.location.href = '/.well-known/assetlinks.json'
          }}
        />
        <Route path='/error/404' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />
        <Route path='/set-password' component={SetPassword} />
        <Route path='/auth/registration' component={AuthRegistration} />

        <Route path='/public-freight-create' component={CreatePublicFreight} />

        <Route path='/public/accountants' component={PublicFinancialAdvisor} />

        <Route path='/public-container-detail' component={PublicContainerDetails} />

        <Route path='/public/invitations/groups' component={ForwardPage} />

        <Route exact path='/public/vehicle-buying-selling' component={PublicVehicleBuyingSelling} />

        <Route path='/public/vehicle-buying-selling/:id' component={PublicVehicleBuySellDetails} />

        <Route path='/public/available-vehicles' component={PublicAvailableVehicles} />

        <Route path='/public/freight-exchange' component={PublicFreightPostings} />

        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <>
            {/* <AuthPage> */}
            <Route path='/auth/login' component={Login} />
            <Route path='/auth/forgot-password' component={ForgotPassword} />
            <Redirect from='/auth' exact to='/auth/login' />
            <Redirect to='/auth/login' />
            {/* </AuthPage> */}
          </>
        ) : /*Otherwise redirect to root page (`/`)*/
        forceToChangePassword && forceToChangePassword === 'Y' ? (
          <Route component={RenewPassword} />
        ) : (
          dashRoute(role)
        )}
        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to='/auth/login' />
        ) : (
          <>
            {forceToChangePassword && forceToChangePassword === 'Y' ? (
              <Route component={RenewPassword} />
            ) : (
              <MasterLayout>
                <PrivateRoutes />
              </MasterLayout>
            )}
          </>
        )}
        <Route path='/error' component={ErrorsPage} />

        <Route component={ErrorsPage} />
      </Switch>
      <MasterInit />
    </>
  )
}

export {Routes}
